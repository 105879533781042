import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from 'components/Layout';
import imageUrl from 'assets/images/icons/404.svg';

const NotFoundPage = () => {

  const helmet_settings = {
    title: 'Page Not Found - Element 84, Inc.',
    meta: [
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
    bodyAttributes: {
      class: `page-404`,
    },
  };

  return (
    <Layout>

      <Helmet {...helmet_settings} />

      <section className="site-section">

        <div className="container text-center">
          <img src={imageUrl} alt="Illustrated Image of a planet in space, with stars and question marks" className="404-image" />
          <h1>404</h1>
          <p>
            Woops! Looks like you ended up in another galaxy...
          </p>
          <p>
            <Link to="/" className="button button-underline">
              Back to Home
            </Link>
          </p>
        </div>

      </section>
    </Layout>
  );

};

export default NotFoundPage;