import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import 'assets/stylesheets/application.scss';

import Layout from 'e84-website-styles/src/components/Layout';
import Header from 'components/Header';

import favicon16 from 'assets/images/favicon/favicon-16x16.png';
import favicon32 from 'assets/images/favicon/favicon-32x32.png';

const TemplateWrapper = ({ children, navIsOpen }) => {
  return (
    <Layout>

      <Helmet>
        <title>Element 84, Inc - Ambitious Software Engineering & Design</title>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <meta name="msapplication-TileColor" content="#111111" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <main className="default-view" data-nav={navIsOpen}>

        <Header />

        {children}

      </main>

    </Layout>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  navIsOpen: PropTypes.bool,
};

const mapStateToProps = ({ navigation }) => ({ navIsOpen: navigation });

export default connect(mapStateToProps)(TemplateWrapper);
